.listeO{
    margin-left: 440px;
   
}
button{
    z-index: 99999999 !important;

}

table{
    border-collapse: collapse;
    font-size: 0.9em;
  }
  
  th, td{
    border: 1px solid black;
    padding: 10px;
  }