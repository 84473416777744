@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

html {
  font-family: 'Montserrat', sans-serif;
 
}

.sidebar {
  position: fixed;
  width: 20%;
  height: 100vh;
  background: #ffffff;
  font-size: 25px;
  box-shadow: 0px 3px 16px rgb(47 83 109 / 30%);
  
  top:0px;
  left: 0px; 
  height: 970px;
  z-index: 500;
  
}

.logoStart
{
  text-align: center;
  position: relative;
  margin: 23px 20% 0% 20%;
  width: 60%;
}

.nav {
  position: relative;
  margin: 0 15%;
  text-align: right;
  top: 30%;
  transform: translateY(-50%);
  
}

.nav ul {
  list-style: none;
  
  li {
    position: relative;
    margin: 1.2em 0;
    
    a {
      line-height: 3em;
      // text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.1em;
      color: rgba(#E95717, 1);
      display: block;
      transition: all ease-out 300ms;
      font-size: 14px;
    }
    
    &.active a {
      color: #E95717;
    }
    
    &:not(.active)::after {
      opacity: 1;
    }
    
    &:not(.active):hover a {
      color: rgba(#E95717, 1);
      font-size: 15px;
    }
    
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.1em;
      // background: black;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to right, #D70A63, #E95717)
    }
  }
}

.twitter {
  position: relative;
  width: 75%;
  float: right;
  height: 100vh;
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  a {
    position: relative;
    
    img {
      width: 48px;
      height: 48px;
    }
  }
  
  p {
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.1em;
    color: #E95717;
    font-weight: bold;
    margin-top: 20px;
  }
}


 @media all and (max-device-width: 480px)
{
  a {
    line-height: 3em;
    // text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1em;
    color: rgba(#E95717, 1);
    display: block;
    transition: all ease-out 300ms;
    font-size: 10px;
  }
  
}