

.navbar {
  overflow: hidden;
  background-color: #D40463;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.navbar .titre_2 {
  float: left;
  display: block;
  color: #F8F8F8;
  text-align: center;
  padding: 20px 50px;
  text-decoration: none;
  font-size: 25px;
}

/* .navbar a:hover {
  background: #ddd;
  color: rgb(255, 255, 255);
} */

.main {
   /* position: fixed;  */
  top:0px;
  left: 0px; 
  height: 970px;
}

.mainn{
  top:0px;
  left: 0px; 
  height: 1154px;
}
.contf{
margin:5% 10% 10% 35%;

background-Color:white ;

border-radius:10px;

}

@media all and (max-device-width: 480px)
{

.contf{
  
  margin:100% 10% 10% 35%;
   
}
.menu{
  padding-left: 0%;
    font-size: 0.1em;
}

}
