/* .cercle { 
    height: 100px; 
    width: 100px; 
    background-color: #F39B04;
    border: 5px solid rgba(0, 0, 0, 1);
} */
.flex-container {
    display: flex;
    flex-wrap: nowrap;
    
  }
  
  .flex-container > div {
    background-color: #f1f1f1;
    width: 14%;
    margin: 10px;
    text-align: center;
    
    font-size: 2.2em;
  }
    .cercle1 { 
        /* position: fixed;
        top: 10%;
        right: 10%; */
		height: 50px; 
		width: 50px; 
		background-color: #F39B04;
		border-radius: 50%; 
	   border-radius: 50%; 
       /* display: inline; */
    }

  
    .val{
        opacity: 0;
    }
    .val:hover{
        opacity: 100; 
    }
    .valbouton{
        padding: 0.5%;
        background-color: #848484;
    }
    span.validation{
        padding: 0px;
    }
    .cercle2{
    height: 180px;
    width: 180px;
    background-color: #848484;
    border-radius: 50%;
    border-radius: 50%;
    /* display: inline; */
    margin: -5% -0.5%;
}
.cercle2Conf{
    height: 180px;
    width: 180px;
    background-color: #6C246C; 
    border-radius: 50%;
    border-radius: 50%;
    /* display: inline; */
    margin: -5% -0.5%;
}

    .cercle3{
        /* position: fixed;
        top: 10%;
        right: 30%; */
        height: 170px; 
		width: 170px; 
        background-color: #848484;
		border-radius: 50%; 
	   border-radius: 50%; 
       margin: 0% -1%;
    }
    .cercle3Conf{
        background-color: #E43C44;
        height: 170px; 
		width: 170px;
        border-radius: 50%; 
	   border-radius: 50%; 
       margin: 0% -1%;
    }

        .cercle4{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 180px; 
            width: 180px; 
            background-color: #848484;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -2% 0%;
        }
        .cercle4Conf{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 180px; 
            width: 180px; 
            background-color: #D40463;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -2% 0%;
        }
        .cercle5{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 180px; 
            width: 180px; 
            background-color: #848484;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -1% -1%;
        }
        .cercle5Conf{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 180px; 
            width: 180px; 
            background-color: #ea5b0b;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -1% -1%;
        }
        .cercle6{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 160px; 
            width: 160px; 
            background-color: #848484;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -2% -1%;
        }
        .cercle6Conf{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 130px; 
            width: 130px; 
            background-color: #d60365;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -2% -1%;
        }
        .cercle7{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 150px; 
            width: 150px; 
            background-color: #848484;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -1% -1%;
        }
        .cercle7Conf{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 150px; 
            width: 150px; 
            background-color: #a12a6c;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: -1% -1%;
        }
        .cercle8{
            /* position: fixed;
            top: 10%;
            right: 40%; */
            height: 50px; 
            width:50px; 
            background-color: #F39B04;
            border-radius: 50%; 
        border-radius: 50%; 
        margin: 0% -1%;
        }

        .route{
            text-align: center;
            flex-wrap:nowrap;
            display: flex;
            padding: 5% 2%;
        }
        h5.titre-route{
            font-size: 1.75rem;
            letter-spacing: 2px;
            margin-top: 10%;
        }

        .textdeco{
            writing-mode: vertical-rl;
            letter-spacing: 2px;
            padding: 2% 2% 2% ;
        }


        
.circle-wrap {
    margin: 10px;
    position: fixed;
    right: 10px;
    width: 150px;
    height: 150px;
    background: #fefcff;
    border-radius: 50%;
    border: 1px solid #cdcbd0;
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }
  
  .circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background: #F8F8F8;
    line-height: 120px;
    text-align: center;
    margin-top: 14px;
    margin-left: 14px;
    color: var(--primary-color);
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
  }
  
  /* color animation */
  
  /* 3rd progress bar */
  .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: var(--primary-color);
  }
  
  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(135deg);
  }
  
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }

        
